.container {
	height: 47px;
	width: 250px;
  }

  .character {
	line-height: 40px;
	font: normal normal bold 20px/30px Roboto;
	color: #21409A;
	background-color: white;
	border: 1px solid #C4C4C4;
	border-radius: 8px;
	margin-left: 8px;
	padding-top: 6px;
  }

  .character:first-child {
	margin-left: 0;
  }

  .character--inactive {
	background-color: white;

  }

  .character--selected {
	border: 1px solid black;
	background-color: rgba(255, 255, 255, 0.2);
  }
