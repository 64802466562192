.Title {
    color: #000000;
    margin-top: 15px;
    font-size: 1.1em;
    min-height: auto;
    font-weight: 500;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    text-decoration: none;
}

.LabelHorizontal {
    cursor: default !important;
    display: inline-flex !important;
    font-size: 15px !important;
    line-height: 1.428571429 !important;
    font-weight: 400 !important;
    padding-top: 39px !important;
    margin-right: 0 !important;
    // @media (min-width: 992px): {
    //   float: left //#FormLabel
    // }
}

.Button {
    margin: 5px 0 !important;
    //color: #fff !important;
}

.SearchButton {
    padding: 8px 16px !important;
}

.ModalButton {
    border-radius: 3px;
    border: 1px solid #21409a;
    font-size: 15px;
    font-weight: 400;
    //background-color: #fff;
    color: #21409a;
    cursor: pointer;
}

.filterGrid {
    margin-top: 24px;
    display: flex !important;
    padding: 0px !important;
}

.Right {
    text-align: right !important;
}

.Left {
    text-align: left !important;
}

.Center {
    text-align: center !important;
}

td {
    .title {
        font-weight: bold;
        margin-top: 5px;
    }
}

.autocompleteSelect {
    button {
        border: 1px solid green;
        border-radius: 3px;
        padding: 2px 8px 0 8px;
    }
}


.statementTable {
    width: 100% !important;
    max-width: 100% !important;
    table-layout: fixed;
    tbody {
        td {
            padding: 4px 22px 4px 24px;
            border: none;
        }
        border-top: none;
        border-bottom: none;
    }
    .subItemsDark {
        border: 2px solid #e2e2e2;
        tr {
            background: white !important;
        }
    }
    .subItemsLight {
        border: 2px solid #f1f1f1;
        tr {
            background: white !important;
        }
    }
    .rowSubItemsTitle {
        height: 30px;
    }
}
.bodyDark {
    background: #e2e2e2;
    border-left: 2px solid#e2e2e2;
    border-right: 2px solid #e2e2e2;
}
.bodyLight {
    background: #f1f1f1;
    border-left: 2px solid#f1f1f1;
    border-right: 2px solid #f1f1f1;
}

.rowSubItems {
    height: 0 !important;
    .detailsContent {
        line-height: 1;
        margin-bottom: 2px;
    }
}
