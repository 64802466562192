.Container {
    padding: 160px 0 0 0;
}

// BANNER --------------------
.parent {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    // max-width: 1440px;
    height: 170px;
    color: #000;
    padding: 20px;
    margin: auto;
    width: 101%;
    position: absolute;
    top: 0;
    z-index: 999;
}
.footer {
    position: relative;
    bottom: 0px;
    color: #fff;
    background-color: #21409a;
    width: 100%;
    height: 50px;
    z-index: 2000;
    text-align: right;
}

.mailtoText {
    color: #fff;
    font-family: inherit;
}

.mailtoText,
.mailtoText:hover {
    text-decoration: none;
    color: white;
}

.footerText {
    margin-top: 15px !important;
    margin-right: 10px !important;
}

.headerContainer {
    margin: 0 auto;
    width: 70%;
}

.logoBox {
    cursor: pointer;

    @media screen and (max-width: 960px) {
        position: absolute;
        left: 50px;
    }
    @media screen and (max-width: 560px) {
        // position: absolute;
        // left: 0px;
    }
}

.logo {
    position: relative;
    width: 206px;
    top: -20px !important;
    z-index: 999;
}

// BOX INFO --------------------------
.boxInfo {
    position: relative;
    // background-image: linear-gradient(#2faebb, #74ccd4);
    background-image: linear-gradient(#9f9f9f, #cbc9c9);
    height: 90px;
    z-index: 999;
    border-radius: 5px;
    padding: 15px;
    color: #fff;
    margin-bottom: 24px;

    @media screen and (max-width: 700px) {
        width: 100%;
        // position: absolute;
        left: 0;
        // border-radius: unset;
    }
}

.boxInfoText {
    width: 80%;
    height: 100%;
    color: #fff;
    font-size: 22px;
    text-align: center;
    line-height: 20px;
    float: left;
    line-height: 1.2em;
    padding: 5px 0;
}

@media only screen and (max-width: 1024px) {
    .boxInfoText {
        width: 77%;
    }
}

@media only screen and (max-width: 700px) {
    .boxInfoText {
        width: 100%;
        font-size: 16px;
    }
}

.boxInfoButton {
    float: left;
    background-color: #21409a;
    color: #fff;
    width: auto;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;

    @media screen and (max-width: 700px) {
        position: absolute;
        right: 10px;
        margin-top: -140px;
    }
}
