.card {
    border: 1px solid #ccc;
    border-radius: 15px;
    min-width: 262px;
}

.image {
    background-image: url('../../../../assets/img/ValePresente/Vale_presente_ativo.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100px;
    border-radius: 15px 15px 0 0;
}

.imageUtilized {
	background-image: url('../../../../assets/img/ValePresente/Vale_presente_inativo.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: 100px;
	border-radius: 15px 15px 0 0;
}

.imagePartial {
	background-image: url('../../../../assets/img/ValePresente/Vale_presente_parcial.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: 100px;
	border-radius: 15px 15px 0 0;
}

.content {
    color: #333;
    padding: 16px;
    border-top: 1px solid #ccc;
}

.title {
    font-size: 12px;
    margin-top: 8px;
}

.descriptionWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.description {
    font-size: 14px;
    font-weight: bold;
}
