.sweet-alert > p {
    display: flex;
    gap: 13px;
    align-items: center;
    justify-content: center;
}
.sweet-alert > p > span:nth-child(1){
    order: 2;
} 
.sweet-alert > p > span:nth-child(2){
    order: 1;
}
.sweet-alert > p > span *{
    margin: 0 !important;
}

/* .sweet-alert > p > span:nth-child(1) button{
    background-color:#c62424;
} */

.withI18nextTranslation\(Profile\)-button-524{
    padding: 9px 30px;
    border-radius: 8px;
}