.Title {
    color: #000000;
    margin-top: 15px;
    font-size: 1.1em;
    min-height: auto;
    font-weight: 500;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    text-decoration: none;
}

.infoIcon {
    padding: 15px 0 0 0 !important;
    position: relative;
    top: -53%;
    left: 94%;
}

.LabelHorizontal {
    cursor: default !important;
    display: inline-flex !important;
    font-size: 15px !important;
    line-height: 1.428571429 !important;
    font-weight: 400 !important;
    padding-top: 39px !important;
    margin-right: 0 !important;
    // @media (min-width: 992px): {
    //   float: left //#FormLabel
    // }
}

.Error {
    color: #ff0000 !important;
}

.Button {
    margin: 10px 0 !important;
    color: #fff !important;
}

.CancelButton {
    margin: 10px 0 !important;
    color: #21409A !important;
    background-color: #FFF !important;
}

.SearchButton {
    padding: 2px 16px !important;
}

.Right{
    text-align: right !important;
}

.Center{
    text-align: center !important;
}

.profileImage {
    display: inline-block;
    width: 100px;
    height: 100px;
    border: 3px solid #ccc;
    border-radius: 50%;
  
    object-fit: cover;
  }

  .SelectSvgIcon {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 24px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    -moz-user-select: none;
  }

  .MuiFormControl-root {
    background-color: #f1f1f1;
}

.MuiOutlinedInput-notchedOutline {
    border: unset !important;
}