* {
    box-sizing: 0;
}

body {
    background: #ffffff;
    overflow-x: hidden;
}

.boxInfo {
    background-image: linear-gradient(#9f9f9f, #cbc9c9);
    //   background-image: linear-gradient(#2faebb, #74ccd4);
    width: 63%;
    z-index: 999;
    border-radius: 5px;
    padding: 15px;
    color: #fff;
    top: 150px;
    left: 18%;
    @media screen and (max-width: 900px) {
        width: 100%;
        left: 0;
        border-radius: unset;
    }
}

.boxInfoText {
    width: 100%;
    height: 100%;
    color: #fff;
    font-size: 22px;
    text-align: center;
    line-height: 20px;
    line-height: 1.2em;
    padding: 5px 0;
}

.GridItem {
    margin-bottom: 50px !important;
}

.parent {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 170px;
    color: #000;
    margin: auto;
    width: 100%;
    top: 0;
    z-index: 999;
}

.headerContainer {
    width: 65%;
    margin: 0 auto;
    @media screen and (max-width: 900px) {
        display: flex;
        justify-content: center;
    }
}

.logoBox {
    cursor: pointer;
    display: contents;
}

.logo {
    position: relative;
    width: 206px;
    top: -20px !important;
    z-index: 999;
}
