@font-face {
  font-family: 'CVale_Fidelidade';
  src:  url('fonts/CVale_Fidelidade.eot?15ijfg');
  src:  url('fonts/CVale_Fidelidade.eot?15ijfg#iefix') format('embedded-opentype'),
    url('fonts/CVale_Fidelidade.ttf?15ijfg') format('truetype'),
    url('fonts/CVale_Fidelidade.woff?15ijfg') format('woff'),
    url('fonts/CVale_Fidelidade.svg?15ijfg#CVale_Fidelidade') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'CVale_Fidelidade' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-i_seta_link:before {
  content: "\e914";
}
.icon-privacidade:before {
  content: "\e913";
}
.icon-ico_meios_pagamento:before {
  content: "\e912";
}
.icon-exportar_relatorios:before {
  content: "\e90f";
}
.icon-informacao:before {
  content: "\e910";
}
.icon-servicos_contratados_pagamentos:before {
  content: "\e911";
}
.icon-historico_compras:before {
  content: "\e903";
}
.icon-saldo_pontuacao:before {
  content: "\e904";
}
.icon-carteira_digital:before {
  content: "\e905";
}
.icon-termos:before {
  content: "\e906";
}
.icon-regulamento:before {
  content: "\e907";
}
.icon-vale_presente:before {
  content: "\e908";
}
.icon-pesquisa:before {
  content: "\e909";
}
.icon-indicar_amigo:before {
  content: "\e90a";
}
.icon-solicitar_pontos:before {
  content: "\e90b";
}
.icon-config_saque:before {
  content: "\e90c";
}
.icon-extrato:before {
  content: "\e90d";
}
.icon-home:before {
  content: "\e90e";
}
.icon-destaque_saldo:before {
  content: "\e902";
}
.icon-cart_premiacao:before {
  content: "\e900";
}
.icon-whatsApp:before {
  content: "\e901";
}
.icon-carteira_saque:before {
  content: "\e98a";
}
.icon-cart_taxa_transferencia:before {
  content: "\e98b";
}
.icon-cart_taxa_antecipacao:before {
  content: "\e98c";
}
.icon-cart_transferencia:before {
  content: "\e98d";
}
.icon-nf_nao_reconhecida:before {
  content: "\e946";
}
.icon-visualizar_password:before {
  content: "\e947";
}
.icon-visualizar_password_fechado:before {
  content: "\e948";
}
.icon-carteira_tipo_operacao:before {
  content: "\e949";
}
.icon-pontos_expirados:before {
  content: "\e94a";
}
.icon-compra_visualizar:before {
  content: "\e94b";
}
.icon-carteira_operacoes:before {
  content: "\e94c";
}
.icon-carteira_status_operacao:before {
  content: "\e94d";
}
.icon-carteira_cashback:before {
  content: "\e94e";
}
.icon-carteira_valor_compra:before {
  content: "\e94f";
}
.icon-carteira_credito:before {
  content: "\e950";
}
.icon-tempo_expirado:before {
  content: "\e951";
}
.icon-premiacao_estorno_credito:before {
  content: "\e952";
}
.icon-saldo_total:before {
  content: "\e953";
}
.icon-detalhe_pagamento:before {
  content: "\e954";
}
.icon-carteira_compra:before {
  content: "\e955";
}
.icon-carteira_valepresente:before {
  content: "\e956";
}
.icon-carteira_troco:before {
  content: "\e957";
}
.icon-carteira_estorno:before {
  content: "\e958";
}
.icon-carteira_expiracao:before {
  content: "\e959";
}
.icon-pagamento_aplicativo:before {
  content: "\e95a";
}
.icon-seta_acordium_aberto:before {
  content: "\e95b";
}
.icon-seta_acordium_fechado:before {
  content: "\e95c";
}
.icon-alerta_modal:before {
  content: "\e95d";
}
.icon-detalhe_campo:before {
  content: "\e95e";
}
.icon-ico_excluir:before {
  content: "\e95f";
}
.icon-compra:before {
  content: "\e960";
}
.icon-calendario:before {
  content: "\e962";
}
.icon-parcerias:before {
  content: "\e963";
}
.icon-ico_conversao:before {
  content: "\e964";
}
.icon-presentear_amigo:before {
  content: "\e965";
}
.icon-parcialmente_comsumido:before {
  content: "\e968";
}
.icon-presente_recebido:before {
  content: "\e969";
}
.icon-presente_enviado:before {
  content: "\e96a";
}
.icon-acesso_loja:before {
  content: "\e96b";
}
.icon-programa:before {
  content: "\e96c";
}
.icon-anonimo:before {
  content: "\e96d";
}
.icon-cancelado:before {
  content: "\e96e";
}
.icon-contato:before {
  content: "\e96f";
}
.icon-indicacao_amigos:before {
  content: "\e970";
}
.icon-grid_ativo:before {
  content: "\e971";
}
.icon-grid_inativo:before {
  content: "\e972";
}
.icon-grid_pendente:before {
  content: "\e973";
}
.icon-grid_reprovado:before {
  content: "\e974";
}
.icon-ico_cancelar:before {
  content: "\e975";
}
.icon-modal_seta_primeiro:before {
  content: "\e976";
}
.icon-modal_seta_anterior:before {
  content: "\e977";
}
.icon-modal_seta_ultimo:before {
  content: "\e978";
}
.icon-modal_seta_proximo:before {
  content: "\e979";
}
.icon-niveis:before {
  content: "\e97a";
}
.icon-ico_aviso:before {
  content: "\e97b";
}
.icon-ico_notificacoes:before {
  content: "\e97c";
}
.icon-data:before {
  content: "\e97d";
}
.icon-alerta:before {
  content: "\e97e";
}
.icon-notificacoes:before {
  content: "\e97f";
}
.icon-avisos:before {
  content: "\e980";
}
