body {
    background: #ffffff;
    overflow-x: hidden;
}

.Container {
    padding: 200px 0 50px 0;
}

.Error{
    top: 8px !important;
    color:#f44336 !important;
    font-size: 15px !important;
}
.TitleSession {
    font-weight: bold;
    font-size: 18px;
    color: #21409a;
    text-transform: uppercase;
    padding-bottom: 10px;
}

.GridItem {
    margin-bottom: 50px !important;
}

.TextEditor {
    font-family: inherit;
    width:100%;
    border: none;
}

.TextCenter {
    text-align: center
}

.parent {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    // max-width: 1440px;
    height: 170px;
    color: #000;
    padding: 20px;
    margin: auto;
    width: 101%;
    position: fixed;
    top: 0;
    z-index: 999;
}

.headerContainer {
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 0 38px 0 10px;
}

.logoBox {
    cursor: pointer;
    flex-grow: 3;
}

.logo {
    position: relative;
    z-index: 999;
    width:206px;
    top: -20px;
}
