.maxImage {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.SpaceAround {
    margin: 20px 0;
}

.paper {
    padding: 15px;
    margin-bottom: 30px;
}

.fundo {
    background-image: url('../../assets/img/bg_indique_amigo.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
}

// .table {
//     min-width: 100%;
//     margin-bottom: 30px;
// }

// .head {
//     color: #08993a;
// }

.category {
    margin: 10px 0 !important;
    padding: 2px 16px !important;
    color: #fff !important;
}

.more {
    color: #21409a !important;
}

.submit {
    color: #fff !important;
}

.seesBox {
    border: 1px solid #999;
    text-align: center;
    padding: 3px;
}

.seesBoxTitle {
    color: #21409a;
    font-size: 16px;
    size: 16px;
    font-weight: 500;
    background-color: #ddd;
    padding: 5px;
}

.seesBoxSubTitle {
    margin-top: 5px;
    background-color: #eee;
    padding: 5px;
}

.seesBoxValue {
    font-size: 18px;
    padding-top: 10px;
}

.seesBoxValue span {
    font-weight: bold;
    font-size: 22px;
}

.seesBoxButton {
    padding: 20px 0;
}


table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  tbody {
    border: 1px solid #bbbbbb;
  }
}

td, th {
  text-align: left;
  padding: 8px;
  padding-left: 15px;
  small {
      font-size: 10px;
  }
  &:nth-child(2) {
    text-align: right; 
    padding-right: 25px;
  }
}

table tr:nth-child(even) {
  background-color: #f6f6f6;
}

table tr {
    border: none;
}

table thead tr:first-child{
    color:#21409a;
}

.head {
    border: none;
}

.categoryImg {
    width: auto;
    height: 150px;
}

// .categoryParent {
    // display: flex;
    // justify-content: space-between;
// }

.categoryItem {
    height: 145px !important;
}

.categoryTitle {
    text-align: center;
    color: #21409a;
    font-weight: bold;
    border-right: 1px solid #ccc;
    div:last-child {
        border-right: none !important;
      }
}

.categoryScore{
    text-align: center;
    font-weight: bold;
}

.firstProgressBar{
    border-radius: 25px 0px 0px 25px;
    width: 100%;
    background: linear-gradient(to bottom, #e2e2e2 0%, #f3f3f3 100%, #e2e2e2 0%);
}

.progressbar{
    border: none !important;
    width: 100%;
    background: linear-gradient(to bottom, #e2e2e2 100%, #f3f3f3 50%, #e2e2e2 100%);
}

.lastProgressBar{
    border-radius: 0px 25px 25px 0px;
    width: 97% !important;
    border: none !important;
    background: linear-gradient(to bottom, #e2e2e2 0%, #f3f3f3 100%, #e2e2e2 0%);
}

.progressBarBack {
    border-right: 1px solid #515151;

}
.progressBarBackLast {
        border: none !important;
}

.firstProgressBarColor{
    
    border-radius: 25px 25px 25px 25px;
    background: linear-gradient(to right, #0b919f 0%, #3dbac7 100%);
}
.progressbarcolor{
    border: none !important;
}

.lastProgressBarColor{
    border-radius: 0px 25px 25px 0px;
    border: none !important;
    //background: linear-gradient(to right, #3dbac7 0%, #3dbac7 100%);
}

.progressBarChildren{
    position: relative;
    top: 35px;
    font-size: 18px;
    text-align: center;
    width: 100%; 
    color: #c4c4c4 !important;
    border: none !important;
}

.imageGrayscale { 
    filter: grayscale(100%) opacity(40%);
    -webkit-filter: grayscale(100%) opacity(40%);
}