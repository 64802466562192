
.container{
    width:100%;
    box-sizing: border-box;
    .GridItem-grid-391{
        padding:0 !important;
        @media only screen and (min-width:600px){
            padding:0 !important;
        }
    }


}

.Title {
    color: #000000;
    margin-top: 15px;
    font-size: 1.1em;
    min-height: auto;
    font-weight: 500;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    text-decoration: none;
}

.filterGrid {
    margin-top: 24px ;
    display: flex!important;
    padding: 0px !important;
}

.LabelHorizontal {
    cursor: default !important;
    display: inline-flex !important;
    font-size: 15px !important;
    line-height: 1.428571429 !important;
    font-weight: 400 !important;
    padding-top: 39px !important;
    margin-right: 0 !important;
    // @media (min-width: 992px): {
    //   float: left //#FormLabel
    // }
}

.successBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.successBoxTitle{
    font-size: 18px;
    font-weight: bold;;
}

.successBoxSubTitle{
    font-size: 16px;
    font-weight: 400;
    padding: 5px 0;
}

.Button {
    margin: 5px 0 !important;
    //color: #fff !important;
}

.SearchButton {
    padding: 8px 16px !important;
    margin: 20px 0px !important;
}

.Right{
    text-align: right !important;
}

.Left{
    text-align: left !important;
}

.Center{
    text-align: center !important;
}

td {
    .title {
        font-weight: bold;
        color: black;
        margin-top: 5px;
    }
    .detailsContent {
        margin-bottom: 10px;
    }
}

.autocompleteSelect {
    button {
        border: 1px solid green;
        border-radius: 3px;
        padding: 2px 8px 0 8px;
    }
}

.filterGrid {
    margin-top: 24px ;
    display: flex!important;
    padding: 0px !important;
}



.statementTable {
    width: 100% !important;
    max-width: 100% !important;
    tbody {
        //border-top: 15px solid white !important;'
        td {
            padding: 4px 36px 4px 24px;
            border: none;
        }
        border: none;
    }
    .bodyLight {
        td {
            background: #f1f1f1;
        }
    }
    .bodyDark {
        td {
            background: #e2e2e2;
        }
    }
}

.invoice{

    input{
    background-color: #fff;
    width: 100%;

    }}

.numberInvoice{
    width: 40%;
    padding-right:15px;
}

.dataInvoice{
    margin-top: 8px;
    width: 30%;
}

.valueInvoice{
    padding-left: 15px;
    width: 40%;
}

.contentBox{
    width: 100%;
    display: flex;
    @media only screen and (max-width:699px){
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .valueInvoice{
            width: 50%;

        }
        .dataInvoice{
            width: 50% !important;
            padding-left: 53px;
        }
        .numberInvoice{
            width: 90%;
            padding-right:0px;

        }

    }
    @media only screen and (max-width:559px){
        .numberInvoice{
            width: 85%;
            padding-right:0px;

        }
    }
    @media only screen and (max-width:383px){
        .numberInvoice{
            width: 83%;
            padding-right:0px;

        }
        .valueInvoice{
            width: 100%;
            padding-left: 53px;

        }
        .dataInvoice{
            width: 100% !important;
            padding-left: 53px;
        }
        @media only screen and (max-width:341px){
            .numberInvoice{
                width: 80%;
                padding-right:0px;

            }
        }
        @media only screen and (max-width:341px){
            .numberInvoice{
                width: 77%;
                padding-right:0px;

            }
        }
    }

}
