.sectionTitle {
    font-size: 16px;
    display: table;
    white-space: nowrap;
    font-weight: 600;
    padding-bottom: 15px;

    &:after {
        border-top: 3px solid var(--bgcolor);
        content: '';
        display: table-cell;
        position: relative;
        top: 0.7em;
        width: 100%;
        text-align: left;
    }
}

.sectionTitleWithoutOver {
    font-size: 15px;
    display: table;
    white-space: nowrap;
    font-weight: 600;
    padding-bottom: 15px;
    &:after {
        border-top: 3px solid var(--bgcolor);
        content: '';
        display: table-cell;
        position: relative;
        top: 0.7em;
        width: 100%;
        text-align: left;
        left: 0.5%;
    }
}

.sectionTitlePolicy {
    font-size: 20px;
    display: table;
    white-space: nowrap;
    font-weight: 600;
    padding-bottom: 15px;
    &:after {
        border-top: 3px solid var(--bgcolor);
        content: '';
        display: table-cell;
        position: relative;
        top: 10px;
        width: 100%;
        text-align: left;
        left: 1.5%;
    }
}
