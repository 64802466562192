.confirmButton {
    float: right;
}

.formContent {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 30px !important;
    padding-left: 15px !important;
}

.regulationContent {
    &:after {
        content: '';
        width: 100%;
    }
}
