.title {
    font-weight: 600 !important;
    font-size: 13px !important;
}

.description {
    font-weight: 400 !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    font-size: 12px !important;
}

@media (min-width: 800px) {
    .sweet-alert {
        min-width: 700px;
    }
}

.custom-alert .btn.btn-lg.btn-primary {
    display: none !important;
}
