.Container {
    padding: 160px 0 50px 0;
}

.RegisterContainer {
    padding: 145px 0 0 0;
}

.Error {
    top: 8px !important;
    color: #f44336 !important;
    font-size: 15px !important;
}

.TitleSession {
    font-weight: bold;
    font-size: 18px;
    color: #21409a;
    text-transform: uppercase;
    padding-bottom: 10px;
}

.infoIcon {
    // padding: 15px 0 0 0 !important;
    // width: 8.333333% !important;
    color: #5d5d5d;
    padding: 15px 0 0 0 !important;
    position: relative;
    top: -53%;
    left: 0%;
}

.labelIndicationCode {
    color: #333333;
    text-align: left;
    font-size: 14px;
    font: roboto;
    font-weight: 400;
    margin: 0 15px;
}

.inputAcess {
    display: flex;
    align-items: center;
    border-radius: 5px;
    background-color: #f4f4f4;
    position: relative;
    top: 0px;
    height: 70px;
}

.inputAcess .textField {
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px solid #c4c4c4;
    margin-top: 15px;
    margin-right: 13px;
    margin-bottom: 15px;
    height: 40px;
    max-width: 30px;
    color: #21409a;
    font-weight: 800;
    text-indent: 8px;
    &:focus {
        border: 1px solid #000 !important;
    }
}

.inputAcess .textFieldError {
    background-color: #ffffff;
    border-radius: 5px;
    border: 1.5px solid #de2e2e;
    margin-top: 15px;
    margin-right: 13px;
    margin-bottom: 15px;
    height: 40px;
    max-width: 30px;
    color: #de2e2e;
    font-weight: 800;
    text-indent: 8px;
    &:focus {
        border: 1px solid #000 !important;
    }
}

@media (max-width: 489px) {
    .inputAcess {
        display: block;
        border-radius: 5px;
        background-color: #f4f4f4;
        position: relative;
        height: 110px;
    }

    .labelIndicationCode {
        position: relative;
        top: 15px;
        margin-bottom: 10px;
        text-align: left;
        font-size: 14px;
    }
}

.information {
    color: #21409a;
    // font-weight: bold;
}

// TERMOS DE USO ----------------------
.boxAgreements {
    width: 100%;
    margin: 20px 20px 20px 15px;
    background-color: #f4f4f4;
    border-radius: 10px;
    @media screen and (max-width: 900px) {
        margin: 15px 0;
        padding: 10px 20px;
        > div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}

.boxAgreementsLeft {
    float: left;
    padding: 15px 25px 15px 5px;
    border-right: 1px solid #b4b4b4;
    margin: 15px 0;
    @media screen and (max-width: 900px) {
        width: 100%;
        padding: 0px;
        border-right: unset;
        margin: unset;
    }
}

.boxAgreementsRight {
    float: left;
    margin: 10px 0;
    width: 70%;
    text-align: left;
    @media screen and (max-width: 900px) {
        margin: unset;
        width: 100%;
        > div {
            display: flex;
            flex-direction: column;
            margin: 0px;
            padding: 0px !important;
        }
    }
}
// TERMOS DE USO ----------------------

// BOX INFO --------------------------
.boxInfo {
    position: relative;
    background-image: linear-gradient(#9f9f9f, #cbc9c9);
    // background-image: linear-gradient(#2faebb, #74ccd4);
    height: 90px;
    z-index: 999;
    border-radius: 5px;
    padding: 15px;
    color: #fff;
    margin-bottom: 20px;
    @media screen and (max-width: 900px) {
        width: 100%;
        position: absolute;
        left: 0;
        border-radius: unset;
    }
}

.boxInfoText {
    width: 80%;
    height: 100%;
    color: #fff;
    font-size: 22px;
    text-align: center;
    line-height: 20px;
    float: left;
    line-height: 1.2em;
    padding: 5px 0;
}

@media only screen and (max-width: 1024px) {
    .boxInfoText {
        width: 77%;
    }
}

@media only screen and (max-width: 900px) {
    .boxInfoText {
        width: 100%;
        font-size: 16px;
    }
}

.boxInfoButton {
    position: absolute;
    right: 10px;
    top: 10px;
    // float: left;
    background-color: #21409a;
    color: #fff;
    width: auto;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    @media screen and (max-width: 900px) {
        position: absolute;
        right: 10px;
        margin-top: -140px;
    }
}
// BOX INFO --------------------------

@media screen and (min-width: 1300px) {
    .GridItem {
        min-width: 1300px !important;
        max-width: 1300px !important;
    }
}

.GridItem {
    margin-bottom: 0px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
}
.TextEditor {
    font-family: inherit;
    width: 100%;
    border: none;
}

.TextCenter {
    text-align: center;
}

// BANNER ------------------------
.parent {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    // max-width: 1440px;
    height: 170px;
    color: #000;
    //   padding: 20px;
    margin: auto;
    width: 101%;
    position: absolute;
    top: 0;
    z-index: 999;
}

.headerContainer {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    max-width: 1750px;
}

.logoBox {
    cursor: pointer;
    // flex-grow: 3;
    @media screen and (min-width: 1300px) {
        min-width: 1300px !important;
    }
}

.logo {
    position: relative;
    width: 200px;
    top: -20px !important;
    z-index: 999;
}

// BANNER --------------------
//
.footer {
    position: relative;
    bottom: 0px;
    color: #fff;
    background-color: #21409a;
    width: 100%;
    height: 50px;
    z-index: 2000;
    text-align: right;
}

.mailtoText {
    color: #fff;
    font-family: inherit;
}

.mailtoText,
.mailtoText:hover {
    text-decoration: none;
    color: white;
}

.footerText {
    margin-top: 15px !important;
    margin-right: 10px !important;
}

.buttonProgress {
    position: 'absolute';
    top: '50%';
    left: '50%';
    margin-top: -12;
    margin-left: -12;
}

.Error {
    color: #f44336 !important;
}
